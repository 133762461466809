/* dark mode style */
.dark .ReactTable {
	color: #fff !important;
}

.dark * {
	box-shadow: none !important;
}

.dark .main-panel {
	background: #090a0b !important;
}

.dark h2,
.dark h1,
.dark h3,
.dark h4,
.dark h5,
.dark p,
.dark .card h1,
.dark .card-stats-active .btn,
.dark .card label,
.dark * label,
.dark .css-1uccc91-singleValue {
	color: white !important;
}

.dark .css-1rhbuit-multiValue {
	background-color: gray;
}

.dark .ReactTable .-pagination .-btn {
	background-color: #001729;
}

.dark .rt-tr -odd:hover,
.dark tr:hover {
	background-color: #090a0b !important;
}

.dark thead > tr:hover {
	background-color: inherit !important;
}

.dark .ReactTable .rt-table .rt-td {
	border-top: 1px solid rgba(143, 143, 143, 0.267);
	border-right: 1px solid rgba(143, 143, 143, 0.267);
	text-align: center;
}

.dark .ReactTable .rt-table {
	border: none;
}

.dark .form-control,
.dark .css-yk16xz-control,
.dark .css-yk16xz-control:focus,
.dark .modal-content {
	background-color: #090a0b;
	border: 1px solid #000;
	color: #fff !important;
}

.dark .modal-header {
	background-color: #090a0b;
	border: none;
}

.dark .btn-primary {
	background-color: #001729 !important ;
}

.dark .react-select .react-select__control {
	border: 1px solid #444444;
}

.dark hr {
	background-color: #fff !important;
}

.dark .bg-white {
	background: #2a2d34 !important;
	box-shadow: none !important;
}

.dark .card-stats-active hr {
	color: #fff !important;
}

.dark .card-stats-active .card-body,
.dark .card-stats-active .card-footer {
	background-color: #001729 !important;
	color: white;
}

.dark .card {
	box-shadow: none !important;
}

.dark .card,
.dark .swal2-popup {
	background: #2a2d34 !important;
}
.dark .card-plain {
	background: none !important;
}

.dark .sidebar[data-color="sidebar"]:after,
.off-canvas-sidebar[data-color="sidebar"]:after {
	background: #001729;
}
.dark .sidebar .nav .collapse .nav {
	background: #00233d;
}

.dark .action-box {
	background-color: #2b2d31;
}

/* Albert & Peyman 2/8/2023*/
.dark .modal-content,
.dark .modal-header {
	background: #2a2d34 !important;
}

.dark .inventory-select div {
	background-color: rgba(0, 0, 0, 1);
}

.dark * {
	color: lightgrey !important;
}

.dark .form-control:disabled {
	background-color: rgba(120, 120, 120, 0.25);
}

/* end of dark mode style */
