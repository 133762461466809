html * {
	transition-property: all !important;
	transition-duration: 0.6s ease-in-out !important;
}
.logo {
	max-width: 100%;
	margin-bottom: 0;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-top: 1rem;
}
.main-panel {
	min-height: 100vh;
}
.miniLogo {
	max-width: 12rem;
}

hr,
.hr {
	margin: auto;
}

.cursor-pointer {
	cursor: pointer;
}

.login-page {
	/*background: url("../img/bg.svg");*/
	background-size: cover;
	background-position: center;
	background: rgb(63, 94, 251);
	background: radial-gradient(
		circle,
		rgba(63, 94, 251, 1) 0%,
		rgba(0, 78, 137, 1) 100%
	);
}

.toggle-btn {
	position: absolute;
	bottom: 1rem;
	z-index: 99;
	right: 1.5rem;
	cursor: pointer;
	font-size: 0.7rem;
	text-decoration: underline;
}

a:hover,
.a:hover {
	text-decoration: none;
}
.dropdown-menu {
	min-width: 0 !important;
}

.sidebar-logo {
	max-width: 100%;
}
/* override bs class rounding */
.rounded {
	border-radius: 12px !important;
}

/* constrain header img */
.headerImg {
	max-width: 80%;
}

.nav-pills-primary .nav-item .nav-link,
.nav-pills-secondary .nav-item .nav-link {
	color: #6e6e6e !important;
}

.bg-primary > .nav-item > .nav-link {
	color: white !important;
}

.nav-pills-primary .nav-item .nav-link.active,
.nav-pills-secondary .nav-item .nav-link.active {
	color: #fff !important;
}

/* branded button links */
.btn-link-danger:hover {
	background-color: #cc2936 !important;
}
/* branded button links */
.btn-link-warning:hover {
	background-color: #fcd581 !important;
	color: #777 !important;
}

/* branded button links */
.btn-link-success {
	background-color: #329f5b !important;
	color: white !important;
}

/* branded button links */
.btn-link-success:hover {
	background-color: #1ada63 !important;
}
/* custom large top margin */

.mt-10 {
	margin-top: 25%;
}

/* add bg to form inputs */

.input-group-prepend .input-group-text {
	background-color: #b8d5ff;
	padding-right: 0.5rem !important;
}

.dropdown-item > a:hover {
	color: white !important;
}

.sidebar .logo .simple-text .logo-img img,
.logo-img {
	width: 80%;
	height: 80%;
	background-color: none !important;
	padding: 3px;
}

.card-stats .card-body .numbers .card-category {
	color: #535353;
	font-size: 16px;
	line-height: 1.4em;
}

.vertical-align {
	margin-top: 20%;
}

.sidebar .logo a.logo-normal {
	padding: 5px 0 8px;
}

.formsCard {
	overflow: scroll;
}

.bigText {
	font-size: 1.5rem !important;
}

.h3,
h3 {
	margin-bottom: 0 !important;
}

Row,
.row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.customNav {
	box-shadow: 0 0 4px rgb(179, 178, 178);
	padding-top: 2px !important;
	padding-bottom: 0 !important;
}

.dealInputs {
	padding-top: 0.6rem !important;
}

b,
strong {
	font-weight: lighter;
}
.hide {
	display: none !important;
}

.dealImg {
	max-height: 100px;
}

/* increase size of input text */
.form-control {
	font-size: 14px;
}
.radioFont {
	font-weight: lighter;
}

.rt-td > div > img,
.singleCell > img {
	height: auto;
	max-height: 150px !important;
}

.SiriusXM {
	min-width: 150px;
	max-width: 250px;
	width: 100%;
}

@media (max-width: 1600px) {
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6 {
		min-width: 100% !important;
	}
}

@media (max-width: 1920px) {
	.col-xxl-3,
	.col-xxl-4,
	.col-xxl-5,
	.col-xxl-6,
	.col-xxl-7 {
		min-width: 100% !important;
	}
}

/* hide elements on smaller screens */
@media (max-width: 1400px) {
	.noMobile,
	#noMobile {
		display: none !important;
	}
}

/* hide elements at 768px */
@media (max-width: 768px) {
	/* .onMobileScreen,
	#onMobileScreen, */
	table th,
	.logo-mini {
		display: none !important;
	}

	.ReactTable th {
		display: block !important;
	}

	td .btn {
		min-width: 48%;
		margin-top: 20px !important;
		margin-right: 1px !important;
		margin-bottom: 20px;
	}

	/*	.text-center {
	 width: 100%; was causing issues with icons within buttons next to text -albert 
	}*/
	/*
	.rt-td > div > img,
	.singleCell > img, */
	.historyButtons,
	.modal-dialog {
		width: 100% !important;
		margin: 10px auto;
		padding: 10px;
	}

	.col,
	.col-6,
	.row {
		width: 100% !important;
		flex: 0 0 100% !important;
		text-align: center !important;
		padding: 0;
	}

	.deal-total {
		text-align: center;
	}

	.card td,
	.card th,
	.forms-table tr,
	.form-group .forms-table {
		min-width: 350px !important;
		width: 100% !important;
		float: left;
		text-align: center;
		margin: 0 auto;
	}

	table,
	tbody {
		display: inline-block; /* whatever, just  reset table layout display to go further */
		overflow: hidden;
	}

	tr {
		display: flex;
		flex-wrap: wrap; /* allow to wrap on multiple rows */
		padding-bottom: 10px;
	}
	td {
		display: block;
		flex: 1; /* to evenly distributs flex elements */
		border: none !important;
		padding: 0 !important;
		margin: 0px !important;
		position: relative;
		text-align: center;
	}
}
/* line height made even for sidebar icons */
.sidebar
	.sidebar-wrapper
	> .nav
	[data-toggle="collapse"]
	~ div
	> ul
	> li
	> a
	i {
	line-height: 30px;
}

/* move to sass file */

.logo-mini {
	display: none !important;
}

.btn.btn-link-black {
	color: #000;
	background-color: transparent;
}

.rt-resizable-header-content {
	text-align: center;
}

.sidebar[data-color="sidebar"] .nav li a {
	color: #f7f7f7;
}

/* add red asterix to req fields */
.required:after {
	content: " *";
	color: red;
}

/*styling for table cells */
.singleCell {
	font-size: 14px;
	padding: 0 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.status {
	font-size: 14px;
	font-weight: 400;
}
/*
.sidebar-mini .sidebar {
	display: block;
	z-index: 999999;
}
*/
.mt-half {
	margin-top: 0.8rem;
}

.lg-p {
	font-size: 1rem;
}

.pt-3-half {
	padding-top: 1.3rem !important;
}

.main-panel .content {
	padding-left: 0;
	padding-right: 0;
}

.lenderImg {
	height: 80px !important;
}

.bodyHeight {
	min-height: 110vh !important;
}

.adImage {
	max-height: 45px !important;
	margin-bottom: 10px;
}

.modal-xl {
	max-width: 94% !important;
}

.btn i,
.navbar .navbar-nav > a.btn i {
	margin-right: 0 !important;
}

/* hide elements on smaller screens */
@media (max-width: 1920px) {
	.sidebarText {
		display: none !important;
	}
}

.old-row {
	margin: 0 -15px !important;
}

.customer-profile {
	max-height: 128px;
	margin-bottom: 0.1rem;
}

/*  action bar  */
.action-bar {
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index: 100;
	max-width: 2300px;
	min-height: 59px;
}

.bottom-nav a {
	float: left;
	display: block;
	color: #f2f2f2;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
}

.bottom-nav a:hover {
	background: #f1f1f1;
	color: black;
}

.bottom-nav a.active {
	background-color: #04aa6d;
	color: white;
}

.toggle {
	position: absolute;
	top: 0.4rem;
	z-index: 9999;
	right: 10rem;
}
.toggle img {
	height: 3rem;
}
@media (max-width: 1000px) {
	.toggle {
		position: absolute;
		top: 0.45rem;
		z-index: 9999;
		right: 4rem;
	}
}
@media (max-width: 1000px) {
	.toggle img {
		height: 2.4rem;
	}
}

/* floating save button */

.float-button {
	position: fixed;
	right: 20px;
	padding: 0.5rem 1.5rem;
	bottom: -120px;
	transition: all 0.2s ease-in 0s;
	z-index: 9999;
	cursor: pointer;
	box-shadow: 0 0 4px 2px rgb(179, 178, 178);
	height: 150px;
}

.float-button:hover {
	bottom: -100px;
	padding: 0.55rem 1.55rem;
	right: 18px;
	font-size: 18px;
}

.float-button-modal {
	position: fixed;
	right: 135px;
	bottom: 30px;
	left: 170px;
	transition: all 0.2s ease-in 0s;
	z-index: 9999;
	cursor: pointer;
	text-align: right;
}
.pb-half {
	padding-bottom: 0.3rem !important;
	padding-top: 0.15rem;
}

.headerToggle {
	position: absolute !important;
	top: 4px;
	left: 49%;
	z-index: 9999;
}
.pt-6 {
	padding-top: 3.5rem;
}

.col-xl-6 {
	flex: 0 0 49%;
}

.collapse-button {
	display: block;
	width: 100%;
}

.collapse-content.collapsed {
	display: none;
}

.collapsed-content.expanded {
	display: block;
}

.modal-body {
	padding: 15px !important;
	color: #000;
}

@media (max-width: 1300px) {
	.mobileHeading {
		padding-top: 5rem !important;
	}
}

/* lazy but i cant find in sass files... */

.btn {
	box-shadow: 0 0 2px #00000042;
}

.vehicle-photo img {
	border-radius: 10px !important;
	overflow: hidden !important;
	margin-top: 10px;
}

.nav-pills-primary .nav-item .nav-link,
.nav-pills-secondary .nav-item .nav-link {
	box-shadow: 0 0 2px #00000042;
	border: 0;
	margin: 5px;
	border-radius: 30px !important;
}
.css-12jo7m5 {
	font-size: 18px !important;
}

.nav-search,
.search {
	display: none;
}

.avatar {
	max-width: 30px;
	padding-top: 0.4rem;
}

.content {
	margin-top: 75px !important;
}

.main-panel {
	background-color: #f4f3ef !important;
	transition: all 0rgb (173, 173, 173) c-bezier(0.685, 0.0473, 0.346, 1);
	transition-property: all;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.main-panel > .content {
	padding: 0;
}

.dropdown-item > p:hover {
	color: white !important;
}

.detailImg {
	max-height: 175px !important;
}

.modal-content {
	background-color: #f4f3ef;
}

.headTopNav {
	margin-top: -2.9rem !important;
	border-bottom: 1px solid lightgray;
}

.kbbLogo {
	max-width: 8rem;
	right: 0;
	position: absolute;
}

.nav-item {
	padding-top: 0.3rem;
}

.favicon-gif {
	margin-bottom: 35px;
}

.letter {
	font-size: 14px;
	font-family: "Roboto!important";
	color: white;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	line-height: 40px;
}

.reminder {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	line-height: 40px;
}

.profile {
	border-radius: 50%;
	width: 40px;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	right: 20px;
}

.login-page,
.forgotten-password {
	min-height: 110vh;
}

.red-close-icon {
	text-align: center;
	cursor: pointer;
	color: rgb(255, 255, 255);
	font-size: 12px;
	background: rgba(202, 6, 6, 0.6);
	border-radius: 50%;
	width: 20px;
	height: 20px;
}

.red-close-icon i {
	font-size: 11px;
}

.red-close-icon:hover {
	background: rgba(202, 6, 6, 0.9);
}

.text-messages-container {
	display: flex;
	flex-direction: column-reverse;
	overflow: auto;
	width: 100%;
	max-height: 30rem;
	margin: 0 auto;
	padding: 0.5rem;
}

.text-messages {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0.2rem;
}

.text-messages-container::-webkit-scrollbar {
	width: 11px;
}

.text-messages-container::-webkit-scrollbar-track {
	background: rgb(231, 231, 231);
	border-radius: 25px;
}

.text-messages-container::-webkit-scrollbar-thumb {
	background: rgb(195, 195, 195);
	border-radius: 25px;
}

.text-messages-container::-webkit-scrollbar-thumb:hover {
	background: rgba(195, 195, 195, 0.8);
}

.text-messages div {
	display: flex;
	flex-direction: column;
	font-size: 0.985em;
	max-width: 15rem;
	margin-bottom: 0.3rem;
	padding: 0.4rem 0.3rem;
	line-height: 18px;
	border-radius: 17px;
}

.text-messages-time {
	font-size: 0.755em;
	margin-bottom: 0.1rem;
	padding: 0 0.2rem;
}

.text-messages-sent {
	align-self: flex-end;
	background: rgb(72, 189, 243);
	color: white;
}

.text-messages-sent .text-messages-time {
	align-self: flex-end;
	margin-left: 1rem;
}

.text-messages-received {
	align-self: flex-start;
	background: rgb(236, 236, 236);
	color: black;
}

.text-messages-sent div,
.text-messages-received div {
	white-space: pre-line;
}

.text-messages-received .text-messages-time {
	align-self: flex-start;
	margin-right: 1rem;
}

.text-messages-send-message {
	padding: 0.75rem 0.35rem 0 0.35rem;
	background: rgb(236, 236, 236);
}

.text-messages-body {
	resize: none;
	padding: 0.75rem;
	border-radius: 10px;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-top: -1rem;
}

.text-messages-body::-webkit-scrollbar {
	display: none;
}

.text-messages-preview {
	background: rgb(236, 236, 236);
}

.text-messages-preview-text {
	font-family: inherit;
	font-size: 1rem;
	padding: 0.85rem 1rem 1rem 1rem;
	white-space: pre-wrap;
}

.ReactTable .rt-th-check,
.ReactTable .rt-td-check {
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 12px 7px;
	overflow: hidden;
	transition: 0.3s ease;
	transition-property: width, min-width, padding, opacity;
	line-height: 2;
}

.forms .ReactTable .rt-tbody {
	display: revert;
}

.forms .singleCell {
	width: 100%;
	display: revert;
	text-align: left;
}

.forms .ReactTable .rt-tr {
	display: table;
}

.forms .ReactTable .rt-table .rt-td {
	text-align: revert;
}

.ReactTable .rt-table {
	border: none;
}

.float-end {
	float: right !important;
}

.table-caption {
	width: 100%;
	font-size: 1.57em;
	caption-side: top;
	text-align: center;
	color: black;
}

/** Twilio Blast Text Styles -- START -- **/
.blast-message-participants {
	max-height: 20rem;
	overflow-y: scroll;
	padding: 0.1rem 1rem 1rem 1rem;
}

.blast-message-participants table thead tr th {
	position: sticky;
	top: 0;
	background-color: #ddd;
	z-index: 10;
}

.blast-message-participants table tbody tr td p {
	color: rgb(235, 14, 14);
	font-style: italic;
	font-size: 0.85em;
	padding: 0;
	margin: 0;
}
/** Twilio Blast Text Styles -- END -- **/
.checkbox-spacing {
	margin-left: 1rem;
}

.button-link {
	background: none !important;
	border: none;
	padding: 0 !important;
	/*optional*/
	font-family: arial, sans-serif;
	/*input has OS specific font-family*/
	color: #069;
	text-decoration: underline;
	cursor: pointer;
}

form.deal-sale div,
form.deal-terms-table div {
	min-width: 50%;
}

div.deal-summary input,
.regular-payment,
.final-payment {
	background-color: transparent !important;
	box-shadow: none !important;
	border: none !important;
	text-align: right;
	font-size: 16px;
	max-width: 50%;
	min-width: 150px;
	float: right;
	padding: 1px;
	color: #444;
}
div.deal-summary label {
	background-color: transparent !important;
	box-shadow: none !important;
	border: none !important;
	width: fit-content;
	display: inline;
	float: left;
	line-height: 125%;
	font-weight: normal;
}

.regular-payment,
.deal-total input,
.amount-financed input {
	font-weight: 700 !important;
	color: #069 !important;
}

.deal-total input {
	margin-right: 20px;
}

.deal-type,
.deal-type p input {
	width: 100%;
	background-color: transparent !important;
	box-shadow: none !important;
	border: none !important;
	text-align: center;
}

input[type="checkbox"] {
	height: 20px;
	width: 20px;
	margin-right: 20px;
}

.list-group-item {
	border: none;
}

.-pagination {
	display: none !important;
}

.flooring-overview {
	border: none !important;
	box-shadow: none !important;
	border: none !important;
	font-weight: 600;
	text-align: left;
	font-size: 20px;
	max-width: 50%;
	min-width: 150px;
	padding: 1px;
	color: #444;
}

.checkbox-box {
	background-color: transparent;

	/* margin: 15px 10px 10px 0;
	padding: 15px 0px 0px 20px; */
	height: auto;
	max-height: 50px;
	transition: all 300ms;

	display: table;
	max-width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	margin: 29px 16px;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-clip: padding-box;
	border: 1px solid rgba(120, 120, 120, 0.5);
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.checkbox-box .col-sm-3 {
	max-width: 100%;
	position: relative;
}

.checkbox-box .col-sm-3 input {
	position: absolute;
	width: 20px;
	height: 20px;
	bottom: 7.5px;
	left: 25px;
}

.bold {
	font-weight: 600;
}
.app-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px; /* Adjust the gap as needed */
	justify-content: center;
	align-items: center;
}
.checkbox-columns {
	display: flex;
	justify-content: space-between;
}

.checkbox-column {
	flex: 1;
}

.checkbox-item {
	display: flex;
	align-items: center;
}
