.image-cropper-container {
	position: relative;
	height: 50vh;
	width: 55%;
	margin-bottom: 2rem;
	background: #071330;
}

.image-cropper-close:hover::after {
	background: rgba(202, 6, 6, 0.8);
}

.image-cropper-container div img {
	border-radius: 0 !important;
}

.image-cropper-close::after {
	content: "X";
	position: absolute;
	cursor: pointer;
	right: 12px;
	top: 12px;
	width: 24px;
	height: 24px;
	font-size: 14px;
	text-align: center;
	background: rgba(202, 6, 6, 0.6);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
}

.image-cropper-undo {
	cursor: pointer;
	position: absolute;
	right: 50px;
	top: 12px;
	font-size: 1.575em;
	color: rgba(255, 255, 255, 0.5);
}

.image-cropper-undo:hover {
	color: rgba(255, 255, 255, 0.8);
}

.image-cropper-controls {
	box-sizing: border-box;
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 0.325rem 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: rgb(195, 206, 218);
	transition: 0.3s ease-in-out;
	height: 50px;
}

.image-cropper-button {
	border-width: 2px;
	font-weight: 600;
	font-size: 0.8571em;
	line-height: 1.35em;
	text-transform: uppercase;
	border: none;
	margin: 6px 1px;
	border-radius: 3px;
	padding: 11px 22px;
	cursor: pointer;
	white-space: nowrap;
	background-color: rgb(12, 65, 96);
	color: #ffffff;
	transition: all 150ms linear;
	margin: 0 0.45rem;
}

.image-cropper-button:hover {
	-webkit-transform: scale(1.02);
	-ms-transform: scale(1.02);
	transform: scale(1.02);
	transition: 0.3s ease;
	background-color: rgba(12, 65, 96, 0.8);
}

.image-cropper-range {
	width: 70%;
	height: 15px;
	-webkit-appearance: none;
	background: rgb(255, 255, 255);
	outline: none;
	border-radius: 15px;
	overflow: hidden;
	margin: 0 1.2rem;
}

.image-cropper-range::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: rgb(195, 206, 218);
	cursor: pointer;
	border: 4px solid #333;
	box-shadow: -407px 0 0 400px rgba(12, 65, 96, 0.8);
}

.image-cropper-zoom {
	cursor: pointer;
	font-size: 1.35em;
	color: rgb(12, 65, 96);
}

.image-cropper-previous {
	cursor: pointer;
	position: absolute;
	top: calc(50% - 50px);
	left: 2%;
	font-size: 4em;
	color: rgba(255, 255, 255, 0.7);
}

.image-cropper-next {
	cursor: pointer;
	position: absolute;
	top: calc(50% - 50px);
	right: 2%;
	font-size: 4em;
	color: rgba(255, 255, 255, 0.7);
}

.image-cropper-previous:hover,
.image-cropper-next:hover {
	color: rgba(255, 255, 255, 0.9);
}

/* Media queries */
@media (max-width: 85em) {
	.image-cropper-container {
		width: 80%;
		height: 50vh;
	}

	.image-cropper-range {
		width: 60%;
	}
}
