.multi-step-form .form-container {
	position: relative;
	margin-top: 1rem;
	margin-bottom: 1rem;
	width: 100%;
	padding: 0 1.325rem;
}

.multi-step-form .form-section {
	margin-top: 3rem;
}

.multi-step-form .section-title {
	font-size: 2.025em;
	margin-left: -1.325rem;
	font-weight: 400;
}

.multi-step-form iframe {
	width: 23rem;
	height: 20rem;
}

.multi-step-form ul {
	margin-bottom: 4rem;
	padding-top: 2rem;
	overflow: hidden;
	counter-reset: step;
	text-align: center;
	font-weight: 100;
}

.multi-step-form ul li {
	cursor: pointer;
	list-style-type: none;
	background-color: #f4f3ef;
	text-transform: uppercase;
	font-size: 0.8255em;
	width: 15%;
	float: left;
	position: relative;
	letter-spacing: 1px;
}

.multi-step-form ul li:before {
	content: counter(step);
	counter-increment: step;
	width: 2.4rem;
	height: 2.4rem;
	display: block;
	line-height: 33px;
	font-size: 1.125em;
	background: white;
	border-radius: 50%;
	margin: 0 auto 10px auto;
	position: relative;
	z-index: 2;
}

.multi-step-form ul li:after {
	content: "";
	width: 100%;
	height: 2px;
	background: white;
	position: absolute;
	left: -50%;
	top: 17px;
	z-index: 1;
}

.multi-step-form ul li:first-child:after {
	content: none;
}

.multi-step-form ul li.active {
	font-weight: 500;
}

.multi-step-form ul li.active:before {
	border-width: 2px;
	border-style: solid;
	border-color: #7deaf8;
	border-radius: 50%;
	background: white;
	animation: pulse-animation 2s infinite;
}

.multi-step-form ul li.active:after {
	background: #7deaf8;
	animation: forward-animation 1s;
	animation-fill-mode: forwards;
}

.multi-step-form ul li.complete {
	font-weight: 500;
}

.multi-step-form ul li.complete:before {
	background: white;
	border-width: 2px;
	border-style: solid;
	border-color: #7deaf8;
	border-radius: 50%;
}

.multi-step-form ul li.complete:after {
	background: #7deaf8;
	animation: forward-animation 1s;
	animation-fill-mode: forwards;
}

.multi-step-form .previous-button {
	position: relative;
	width: 15%;
	background: #94dce7;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 25px;
	cursor: pointer;
	outline: none;
	padding: 10px 5px;
	margin: 3.125rem 0.3125rem 0.125rem 0.3125rem;
	left: 0;
}

.multi-step-form .previous-button:hover {
	box-shadow: 0 0 0 2px white, 0 0 0 3px #7deaf8;
}

.multi-step-form .next-button {
	position: relative;
	width: 15%;
	background: #7deaf8;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 25px;
	cursor: pointer;
	outline: none;
	padding: 10px 5px;
	margin: 3.125rem 0.3125rem 0.125rem 0.3125rem;
	left: 85%;
}

.multi-step-form .next-button:hover {
	box-shadow: 0 0 0 2px white, 0 0 0 3px #7deaf8;
}

.multi-step-form .next-with-previous {
	position: relative;
	width: 15%;
	background: #7deaf8;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 25px;
	cursor: pointer;
	outline: none;
	padding: 10px 5px;
	margin: 3.125rem 0.3125rem 0.125rem 0.3125rem;
	left: 68%;
}

.multi-step-form .next-with-previous:hover {
	box-shadow: 0 0 0 2px white, 0 0 0 3px #7deaf8;
}

.multi-step-form .submit-button {
	position: relative;
	width: 15%;
	background: #0066a2;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 25px;
	cursor: pointer;
	outline: none;
	padding: 10px 5px;
	margin: 3.125rem 0.3125rem 0.125rem 0.3125rem;
	left: 68%;
}

.multi-step-form .submit-button:hover {
	box-shadow: 0 0 0 2px white, 0 0 0 3px #7deaf8;
}

@keyframes pulse-animation {
	0% {
		box-shadow: 0 0 0 0 rgba(125, 234, 248, 0.4);
	}
	70% {
		box-shadow: 0 0 0 10px rgba(33, 131, 221, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(33, 131, 221, 0);
	}
}
@keyframes forward-animation {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
